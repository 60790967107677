<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>이메일 발송</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-cols">
            <!--템플릿목록-->
            <div class="jh-form-wrap is-pt-10 is-col-fix" style="width: 50%;" v-show="template">
                <div class="jh-ui-header">
                    <h2>이메일 템플릿</h2>
                    <div class="is-right">
                        <v-text-field clearable class="jh-form" style="width: 250px;" placeholder="제목검색" v-model="searchValue.TITLE" @keypress.enter="search">          
                            <template v-slot:append>
                                <v-btn class="jh-btn is-icon" @click="search"><i class="jh-icon-search-sm is-blue"></i></v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <data-tables
                    ref="dataTable"
                    :data-table-options="dataTableOptions"
                    @click:row="onClickRow"
                    :paginationOptions="paginationOptions"
                    v-model="selectedRows"
                />
            </div>
            <!--템플릿목록-->
            <div class="jh-card" style="min-width: 800px;">
                <div class="jh-card-header is-jh-10">
                    <div class="is-left">
                        <!-- <v-btn class="jh-btn is-icon" :title="[template ? '확대' : '축소']" @click="template=!template"><i :class="[template ? 'jh-icon-transfer-left' : 'jh-icon-transfer-right']"></i></v-btn> -->
                        <h1 class="is-ml-5">발송내용</h1>
                    </div>
                    <div class="is-right">                                    
                        <v-btn class="jh-btn" @click="openE030202P01"><i class="jh-icon-popup"></i><span>발송이력</span></v-btn>
                    </div>
                </div>
                <div class="jh-card-body">
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="80px">
                            <col>
                            <col width="80px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label>고객번호</label></th>
                                <td>
                                    <v-text-field
                                        class="jh-form"
                                        clearable
                                        maxlength="16"
                                        v-model="customerInfo.CUST_NO"
                                        oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').replace(/(\..*)\./g, '$1');"
                                        @input="onInputCustNo"
                                        @keypress.enter="getCustomerInfo"
                                    >          
                                        <template v-slot:append>
                                            <v-btn class="jh-btn is-icon" @click="getCustomerInfo"><i class="jh-icon-search-sm is-blue"></i></v-btn>
                                        </template>
                                    </v-text-field>
                                </td>
                                <th><label>고객명</label></th>
                                <td>
                                    <span class="is-value">{{ customerInfo.CUST_NM }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th><label>이메일주소</label></th>
                                <td colspan="3">
                                    <v-text-field class="jh-form" placeholder="" v-model="customerInfo.CUST_EML_ADDR" />
                                </td>
                            </tr>
                            <tr>
                                <th><label>제목</label></th>
                                <td colspan="3">
                                    <v-text-field class="jh-form" v-model="emailTemplateDetail.TITLE" />
                                    <!-- {{ titleLen }} / 255 -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="jh-editor is-mt-10">
                        <ckeditor v-if="lodash.includes($options.name, active_tab_id) || isPopup" v-model="emailTemplateDetail.CONTENT" :config="editorConfig"></ckeditor>                        
                    </div>
                    <div class="jh-txt-counter is-txt-right"><strong :class="contentLen > 5080 ? 'is-txt-red': ''">{{contentLen | inputNumberFormat}}</strong> / 5,080 byte</div>
                </div>
                <div class="jh-card-footer">
                    <v-btn class="jh-btn is-md is-main" style="width: 100px;" v-if="mixin_set_btn($options.name, 'btnSend')" :loading="isLoading" @click="send">발송</v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

import iconv from "iconv-lite";
import he from "he";

export default {
    name: "MENU_E030201", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables
    },
    props: {
        isPopup: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            lodash: _,

            template: true,

            isLoading: false,

            searchValue: {
                CHNL_KIND: "EMAIL",
                TITLE: null,
                USE_YN: "Y"
            },

            inquiredCustomerNO: null,

            dataTableOptions: {
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "ROWNUM", align: "center", width: "50px" },
                    { text: "제목", value: "TITLE" },
                ],
                height: "680px",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList
            },
            paginationOptions: {
                totalVisible: 10
            },

            editorConfig: {
                extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
                height: "425px",
                toolbar: [
                    { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
                    { name: "editing" }, //, items: [ "Scayt" ]
                    { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
                    { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
                    { name: "forms" },
                    { name: "styles", items: [ "Font", "FontSize" ] },
                    { name: "styles", items: [ "Styles", "Format" ] },
                    { name: "others", items: [ "-" ] },
                    "/",
                    { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
                    { name: "basicstyles", items: [ "Bold", "Italic", "Strike", "-", "RemoveFormat" ] },
                    { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
                    { name: "document", items: [ "Source" ] },
                    { name: "tools", items: [ "Maximize" ] },
                    { name: "about", items: [] }
                ],
                toolbarCanCollapse:  true,
            },

            requestData: {
                headers: {
                    SERVICE: "message.template",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },

            selectedKey: null,
            selectedRows: [],

            customerInfo: {
                CUST_NO: null,
                CUST_NM: null,
                CUST_EML_ADDR: '',
                IsInquire: false
            },

            emailTemplateDetail: {
                ID: null,
                TITLE: "",
                CONTENT: ""
            },
        };
    },
    filters: {
        inputNumberFormat(v){
            return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {
        ...mapGetters({
            active_tab_id: "commonStore/GE_COMMON_ACTIVE_TAB_ID",
        }),
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },
        titleLen: function() {
            return iconv.encode(he.decode(this.emailTemplateDetail.TITLE), "euc-kr").length;
        },
        contentLen: function() {
            // TR전송할 때와 동일하게 개행문자, 탭을 제거하고 사이즈 체크
            let content = _.replace(this.emailTemplateDetail.CONTENT, /\n/gi, "");
            content = _.replace(content, /\t/gi, "");
            return iconv.encode(this.restoreXSS_CKeditor(content), "euc-kr").length;
        }
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams();
        },

        setDataTableParams: function() {
            this.requestData = _.cloneDeep(this.requestData);
            // header 세팅
            this.requestData.headers["URL"] = "/api/message/template/list";
            this.requestData.headers["METHOD"] = "list";
            this.requestData.headers["ASYNC"] = false;

            // sendData 세팅
            this.requestData.sendData = this.searchValue;
            // this.requestData.sendData["USER_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        getList: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            this.requestData.headers["PAGES_CNT"] = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
            else this.requestData.sendData["SORT_ORDR"] = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData.sendData["SORT_DRCT"] = undefined;

            return this.common_postCall(this.requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    item.CONTENT = this.restoreXSS_CKeditor(item.CONTENT);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search: function() {
            this.setDataTableParams();

            if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
        },
        onInputCustNo: function(e) {
            if (this.inquiredCustomerNO != e) {
                this.customerInfo.CUST_NM = null;
                this.customerInfo.CUST_EML_ADDR = null;
                this.customerInfo.IsInquire = false;
            }
        },
        getCustomerInfo: function() {
            if (!this.customerInfo.CUST_NO || this.customerInfo.CUST_NO.length > 8) {
                this.common_alert("고객번호는 8자리 이상 입력해야 합니다.", "noti");
                return false;
            }

            let requestData = {
                headers: {
                    SERVICE: "message.channel",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };

            // header 세팅
            requestData.headers["URL"] = "/api/message/channel/customerinfo/inquire";
            requestData.headers["METHOD"] = "inquire";
            requestData.headers["ASYNC"] = false;

            // sendData 세팅
            requestData.sendData.SRCH_KEY = this.customerInfo.CUST_NO;
            requestData.sendData.QryTpCode = "0"; // 고객번호로 조회 시 "0"

            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                // this.customerInfo.CUST_NO = data[0].ClntNo;
                this.customerInfo.CUST_NM = data[0].ClntNm;
                this.customerInfo.CUST_EML_ADDR = data[0].EmailAddr;
                this.customerInfo.IsInquire = true;
                this.inquiredCustomerNO = data[0].ClntNo;
            });
        },
        send: function() {
            if (!_.isEmpty(this.customerInfo.CUST_NO) && (this.customerInfo.CUST_NO.length != 8 || (this.customerInfo.CUST_NO != "99999999" && !this.customerInfo.IsInquire))) {
                this.common_alert("고객번호없이 이메일 주소를 입력하거나\n8자리의 고객번호를 입력 후 고객정보를 조회해야 합니다.", "noti");
            }
            else if (!this.customerInfo.CUST_EML_ADDR) {
                this.common_alert("이메일 주소를 입력해야 합니다.", "noti");
            }
            else if (!/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/.test(this.customerInfo.CUST_EML_ADDR)) {
                this.common_alert("잘못된 이메일 주소입니다.", "noti");
            }
            else if (!this.emailTemplateDetail.TITLE) {
                this.common_alert("제목을 입력해야 합니다.", "noti");
            }
            else if (this.titleLen > 255) {
                this.common_alert(`제목은 255Byte를 초과할 수 없습니다.\n현재 ${this.titleLen}Byte 입력하셨습니다.`, "noti");
            }
            else if (!this.emailTemplateDetail.CONTENT) {
                this.common_alert("내용을 입력해야 합니다.", "noti");
            }
            else if (this.contentLen > 5080) {
                this.common_alert(`내용은 5,080Byte를 초과할 수 없습니다.\n현재 ${this.contentLen}Byte 입력하셨습니다.`, "noti");
            }
            else {
                this.common_confirm('발송하시겠습니까?', this.sendEmail, null, null, null, 'chk');
            }
        },
        sendEmail: function() {
            this.isLoading = true;

            let requestData = {
                headers: {
                    SERVICE: "message.email",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };
            
            // header 세팅
            requestData.headers["URL"] = "/api/message/email/send";
            requestData.headers["METHOD"] = "send";
            requestData.headers["ASYNC"] = false;

            // 고객번호가 없을 경우 99999999으로 셋팅
            if (_.isEmpty(this.customerInfo.CUST_NO)) this.customerInfo.CUST_NO = "99999999";
            // sendData 세팅
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData.USER_NM = this.$store.getters["userStore/GE_USER_ROLE"].userName;
            requestData.sendData.TITLE = this.emailTemplateDetail.TITLE;
            requestData.sendData.CONTENT = this.restoreXSS_CKeditorDec(this.emailTemplateDetail.CONTENT);
            requestData.sendData.CUST_NO = this.customerInfo.CUST_NO;
            requestData.sendData.CUST_NM = this.customerInfo.CUST_NM;
            requestData.sendData.CUST_EML_ADDR = this.customerInfo.CUST_EML_ADDR;

            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    // this.isLoading = false;
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                this.common_alert("정상 처리되었습니다.", "done");
                // this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        },

        onClickRow: function(item, row) { 
            if (this.selectedKey === item.ID) {
                row.select(false);
                this.selectedKey = null;
                this.emailTemplateDetail = {
                    ID: null,
                    TITLE: "",
                    CONTENT: ""
                };
            } else {
                row.select(true);
                this.selectedKey = item.ID;
                this.emailTemplateDetail = Object.assign(this.emailTemplateDetail, item);
            }
        },

        setCustomer: function(info) {
            this.customerInfo.CUST_NO = info.custNo;
            this.customerInfo.CUST_NM = info.custName;
            this.customerInfo.CUST_EML_ADDR = info.emailAddress;
            this.customerInfo.IsInquire = true;
            this.inquiredCustomerNO = info.custNo;
        },

        openE030202P01() {
            this.$openWindow('E030202P01','E030202P01','1600','900');
        }
    },
    created() {},
    mounted() {
        this.init();
    },
    watch: {}
};
</script>

<style scoped>
/* .jh-editor > div {
    height: auto;
} */
</style>